<template>
  <section class="landing-page">
    <div class="banner-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand" href="../../index.html"><img src="../../../assets/images/logo.svg" alt=""></a>
              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="mdi mdi-menu"></span>
              </button>
              <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <ul class="navbar-nav">
                  <li class="nav-item active">
                    <a class="nav-link" href="javascript:void(0);">HOME</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0);">PRICING</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0);">CONTACT</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div class="row py-sm-5 py-0">
          <div class="col-lg-7 banner-content">
            <h1 class="mr-2 text-white"> All you need for creating dashboard UI and complex web interfaces. </h1>
            <h3 class="font-weight-light text-white pt-2 pb-5"> Star Admin Template </h3>
            <div class="my-5">
              <button class="btn btn-primary btn-lg mr-2 mb-3">VIEW ALL DEMO</button>
              <button class="btn btn-danger btn-lg ml-2 mb-3">BUY NOW</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="details-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-sm-4 pb-3">
            <div class="card card-icon-top">
              <div class="card-body text-center">
                <img src="../../../assets/images/samples/bootstrap-stack.png" alt="image" class="card-icon">
                <h4>Bootstrap 4</h4>
                <p class="card-text"> Bootstrap is an open source toolkit for developing with HTML, CSS, and JS. </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 pb-3">
            <div class="card card-icon-top">
              <div class="card-body text-center">
                <img src="../../../assets/images/samples/angular-4.png" alt="image" class="card-icon">
                <h4>Angular 5</h4>
                <p class="card-text"> Angular is a platform that makes it easy to build applications with the web. </p>
              </div>
            </div>
          </div>
          <div class="col-sm-4 pb-3">
            <div class="card card-icon-top">
              <div class="card-body text-center">
                <img src="../../../assets/images/samples/html5.png" alt="image" class="card-icon">
                <h4>Static HTML</h4>
                <p class="card-text"> This is built with Jquery and static HTML that can be integrated to any framewroks </p>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5 mb-5">
          <div class="col-md-8 mx-auto py-5 text-center">
            <h1>Choose Your Demo</h1>
            <h4 class="font-weight-light"> This template comes with AngularJs and HTML5/jQuery version, which helps to choose the best dashboard solution to fit your project needs. </h4>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-4 grid-margin">
            <div class="m-3">
              <div class="card card-item-preview">
                <img class="card-img-top" src="../../../assets/images/samples/dashboard.png" alt="image">
                <div class="card-body py-3 border-top">
                  <label class="badge badge-warning">New</label>
                  <h3 class="font-weight-normal mb-0 text-center">Dashboard</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 grid-margin">
            <div class="m-3">
              <div class="card card-item-preview">
                <img class="card-img-top" src="../../../assets/images/samples/widgets.png" alt="image">
                <div class="card-body py-3 border-top">
                  <h3 class="font-weight-normal mb-0 text-center">Widgets</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 grid-margin">
            <div class="m-3">
              <div class="card card-item-preview">
                <img class="card-img-top" src="../../../assets/images/samples/modal.png" alt="image">
                <div class="card-body py-3 border-top">
                  <h3 class="font-weight-normal mb-0 text-center">Modals</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 grid-margin">
            <div class="m-3">
              <div class="card card-item-preview">
                <img class="card-img-top" src="../../../assets/images/samples/e-commerce.png" alt="image">
                <div class="card-body py-3 border-top">
                  <h3 class="font-weight-normal mb-0 text-center">E-commerce</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 grid-margin">
            <div class="m-3">
              <div class="card card-item-preview">
                <img class="card-img-top" src="../../../assets/images/samples/email.png" alt="image">
                <div class="card-body py-3 border-top">
                  <h3 class="font-weight-normal mb-0 text-center">Email</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 grid-margin">
            <div class="m-3">
              <div class="card card-item-preview">
                <img class="card-img-top" src="../../../assets/images/samples/charts.png" alt="image">
                <div class="card-body py-3 border-top">
                  <label class="badge badge-success">New</label>
                  <h3 class="font-weight-normal mb-0 text-center">Charts</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 grid-margin">
            <div class="m-3">
              <div class="card card-item-preview">
                <img class="card-img-top" src="../../../assets/images/samples/editors.png" alt="image">
                <div class="card-body py-3 border-top">
                  <h3 class="font-weight-normal mb-0 text-center">Code-Editors</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 grid-margin">
            <div class="m-3">
              <div class="card card-item-preview">
                <img class="card-img-top" src="../../../assets/images/samples/forms.png" alt="image">
                <div class="card-body py-3 border-top">
                  <label class="badge badge-info">New</label>
                  <h3 class="font-weight-normal mb-0 text-center">Forms</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 grid-margin">
            <div class="m-3">
              <div class="card card-item-preview">
                <img class="card-img-top" src="../../../assets/images/samples/popup.png" alt="image">
                <div class="card-body py-3 border-top">
                  <h3 class="font-weight-normal mb-0 text-center">Popups</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row pt-5">
          <div class="col-md-12 pb-5 text-center">
            <h1>Top Features</h1>
          </div>
          <div class="feature-list pt-5">
            <div class="row feature-list-row text-center border-bottom">
              <div class="col-lg-3 feature-list-item border-right">
                <i class="mdi mdi-briefcase"></i>
                <h3>Lots of widgtes</h3>
                <p class="feature-description">Wide range of Widgets are available with this Package.</p>
              </div>
              <div class="col-lg-3 feature-list-item border-right">
                <i class="mdi mdi-target"></i>
                <h3>Bootstrap 4</h3>
                <p class="feature-description">It is made with bootstrap 4 framework</p>
              </div>
              <div class="col-lg-3 feature-list-item border-right">
                <i class="mdi mdi-cellphone"></i>
                <h3>Fully Responsive</h3>
                <p class="feature-description">Fully responsive accross all devices</p>
              </div>
              <div class="col-lg-3 feature-list-item">
                <i class="mdi mdi-emoticon-happy-outline"></i>
                <h3>3000+ icon fonts</h3>
                <p class="feature-description">Lots of icon fonts are included in the package</p>
              </div>
            </div>
            <div class="row feature-list-row text-center">
              <div class="col-lg-3 feature-list-item border-right">
                <i class="mdi mdi-select-compare"></i>
                <h3>Dark and light sidebars</h3>
                <p class="feature-description">Included Dark and Light Sidebar for getting desired look and feel.</p>
              </div>
              <div class="col-lg-3 feature-list-item border-right">
                <i class="mdi mdi-monitor"></i>
                <h3>Easy to customize</h3>
                <p class="feature-description">Customization will be easy as we understand your pain.</p>
              </div>
              <div class="col-lg-3 feature-list-item border-right">
                <i class="mdi mdi-format-color-fill"></i>
                <h3>6 theme colors</h3>
                <p class="feature-description">We have included 6 pre-defined color schemes with this admin.</p>
              </div>
              <div class="col-lg-3 feature-list-item">
                <i class="mdi mdi-receipt"></i>
                <h3>Detailed documentation</h3>
                <p class="feature-description">We have made detailed documentation, for ease of use.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="border-bottom">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <img class="img-fluid" src="../../../assets/images/logo.svg" alt="">
              <div class="d-flex pt-5 pb-5">
                <div class="social-icons bg-info text-white"><span class="mdi mdi-facebook"></span></div>
                <div class="social-icons bg-secondary text-white"><span class="mdi mdi-pinterest"></span></div>
                <div class="social-icons bg-danger text-white"><span class="mdi mdi-dribbble"></span></div>
                <div class="social-icons bg-info text-white"><span class="mdi mdi-twitter"></span></div>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="d-flex">
                <i class="mdi mdi-cellphone-android"></i>
                <h6 class="font-weight-normal">(0000) 1234 5678</h6>
              </div>
              <div class="d-flex">
                <i class="mdi mdi-settings"></i>
                <h6 class="font-weight-normal">support@staradmin.org</h6>
              </div>
              <ul class="nav mt-3">
                <li class="nav-item">
                  <a class="nav-link pl-0" href="javascript:void(0);">Demos</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);">Features</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);">Support</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="javascript:void(0);">Documentation</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-3">
              <h3 class="text-white">Newsletter</h3>
              <p>To get the latest news from us please subscribe your email.</p>
              <div class="mt-3">
                <input type="email" class="form-control mb-2" placeholder="Enter your email"> </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-sm-8">
            <h4 class="mt-3">Copyright © 2019 <a href="javascript:void(0);">bootstrapdash</a>. All rights reserved.</h4>
          </div>
          <div class="col-sm-4">
            <h4>Hand-crafted &amp; made with <i class="mdi mdi-heart text-danger d-inline-block"></i>
            </h4>
          </div>
        </div>
      </div>
    </footer>
  </section>
</template>

<script>
export default {
  name: 'landing-page'
}
</script>